import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import View from 'ol/View.js';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';
import Cluster from 'ol/source/Cluster.js';
import { Style, Circle as CircleStyle, Fill, Stroke, Text, Icon } from 'ol/style.js';
import Overlay from 'ol/Overlay.js';
import { fromLonLat, toLonLat } from 'ol/proj.js';
import { showPopUp, closePopUp } from '../ui/popup.js';
import { rightPanelSetInfo, closeRightPanel } from '../ui/rightpanel.js';
import { closeLeftPanel } from '../ui/leftpanel.js';
import Settlement from './settlements.js';
import Province from './provinces.js';
import Army from './armies.js';

import { supabase } from '../supabaseClient.js';

export default class MapObj {
	constructor() {
		this.settlements = {};
		this.provinces = {};
		this.armies = {};
		this.highlightedProvinces = [];
		this.armyLayers = [];
		this.RightPanel = '';
		this.firstSettlementPlacementRequired = false;
    	this.settlementPlacementMode = false;
    	this.cursorImage = null;

		this.settlementsLayer = new VectorLayer({
			source: new VectorSource(),
			ZIndex: 99,
		});

		this.armiesSource = new VectorSource();
		this.clusterSource = new Cluster({
		distance: 50, // Pixel distance between points before clustering
		source: this.armiesSource
		});

		this.armiesLayer = new VectorLayer({
			source: this.clusterSource,
			style: this.getArmyClusterStyle.bind(this), // Apply a custom style for clustered armies
			zIndex: 100
		  });

		  const defaultStyle = new Style({
			fill: new Fill({
			  color: '#FAEBD7',
			}),
			stroke: new Stroke({
			  color: '#404242',
			  width: 2,
			}),
		  });
	  
		  const dottedLineStyle = new Style({
			stroke: new Stroke({
			  color: '#404242',
			  width: 2,
			  lineDash: [8, 4], // Dotted line pattern
			}),
		  });
	  
		  this.provincesLayer = new VectorLayer({
			background: '#96c7eb',
			source: new VectorSource({
			  url: 'map.geojson',
			  format: new GeoJSON(),
			}),
			style: function (feature, resolution) {
			  if (feature.get('line') === 'true') {
				return dottedLineStyle; // Apply dotted line style
			  } else {
				return defaultStyle; // Apply default style
			  }
			},
		  });

		this.map = new Map({
			layers: [this.provincesLayer, this.settlementsLayer, this.armiesLayer],
			target: 'map',
			view: new View({
				center: fromLonLat([-0.1276, 51.5074]),
				zoom: 6,
			}),
		});

		this.highlight = null;

		this.popupContainer = document.getElementById('popup');

		this.overlay = new Overlay({
			element: this.popupContainer,
			autoPan: true,
			autoPanAnimation: {
				duration: 5,
			},
		});
		this.map.addOverlay(this.overlay);


		this.map.on('click', this.onMapClick.bind(this));
	}

	promptFirstSettlementPlacement() {
		alert("Please place your settlement on the map.");
		this.settlementPlacementMode = true;
	
		// Create an image element to follow the cursor
		this.cursorImage = document.createElement('img');
		this.cursorImage.src = 'dot.png';
		this.cursorImage.style.position = 'absolute';
		this.cursorImage.style.pointerEvents = 'none';
		this.cursorImage.style.width = '32px';
		this.cursorImage.style.height = '32px';
		document.body.appendChild(this.cursorImage);
	
		// Bind the event handlers
		this.settlementPlacementHandler = this.handleSettlementPlacement.bind(this);
		this.updateCursorImagePositionHandler = this.updateCursorImagePosition.bind(this);
	
		this.map.on('click', this.settlementPlacementHandler);
		this.map.getViewport().addEventListener('pointermove', this.updateCursorImagePositionHandler);
	}

	updateCursorImagePosition(evt) {
		if (this.settlementPlacementMode && this.cursorImage) {
		  // Get mouse position
		  const mouseX = evt.clientX;
		  const mouseY = evt.clientY;
	
		  // Update image position
		  this.cursorImage.style.left = mouseX - 16 + 'px';
		  this.cursorImage.style.top = mouseY - 16 + 'px';
		}
	}

	async handleSettlementPlacement(evt) {
		if (this.settlementPlacementMode) {
		  const coordinate = evt.coordinate;
		  const lonLat = toLonLat(coordinate);
	
		  // Get the province at the clicked location
		  const pixel = this.map.getEventPixel(evt.originalEvent);
		  const feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
			return feature;
		  });
	
		  if (feature && this.featureInLayer(feature, this.provincesLayer)) {
			const province_id = feature.get('id');
	
			// Remove the cursor image and event listeners
			document.body.removeChild(this.cursorImage);
			this.cursorImage = null;
			this.map.un('click', this.settlementPlacementHandler);
			this.map.getViewport().removeEventListener('pointermove', this.updateCursorImagePositionHandler);
			this.settlementPlacementMode = false;

			console.log(window.currentUser.id);

			const { data: { session } } = await supabase.auth.getSession();
			const accessToken = session.access_token;
	
			// Call the API to place the settlement
			fetch('/api/place_settlement', {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json',
			  	'Authorization': `Bearer ${accessToken}`
			  },
			  body: JSON.stringify({
				user_id: window.currentUser.id,
				coords: lonLat.join(','),
				name: window.currentUser.user_metadata.settlement_name,
				province_id: province_id,
			  }),
			})
			.then(response => response.json())
			.then(data => {
			  // Handle success
			  console.log('Settlement placed:', data);
	
			  // Reload settlements on the map
			  this.loadSettlements(window.currentUser.id);
			})
			.catch((error) => {
			  console.error('Error placing settlement:', error);
			});
		  } else {
			alert("Please click on a province to place your settlement.");
		  }
		}
	}

	featureInLayer(feature, layer) {
		return layer.getSource().getFeatures().includes(feature);
	}


	onMapClick(evt) {
		this.displayFeatureInfo(evt.pixel, evt.coordinate);
	}

	getArmyClusterStyle(feature) {
		 // Get the number of features in the cluster
		const features = feature.get('features');    
		const size = features.length; 
		const totalArmySize = features.reduce((sum, feature) => {
			return sum + (feature.get('size') || 0); // Sum the sizes of all armies in the cluster
		}, 0);

		let style;
	
		if (size > 1) {
			// Style for clusters
			style = new Style({
				image: new Icon({
					src: 'army_icon2.png', // Path to your icon image
					anchor: [0.5, 1.5], // Anchor to place the icon above the circle (adjust as needed)
					scale: 0.025, // Scale the icon size if necessary
				}),
				text: new Text({
					text: totalArmySize.toString() + "K", // Display the cluster size
					fill: new Fill({
						color: '#fff' // White text
					}),
					font: 'bold 16px sans-serif'
				})
			});
		} else {
			// Style for individual armies (non-clustered)
			const army = feature.get('features')[0]; // Get the army feature
			const armySize = army.get('size'); // Assuming army has a size property
	
			style = new Style({
				image: new Icon({
					src: 'army_icon2.png', // Path to your icon image
					anchor: [0.5, 1.5], // Anchor to place the icon above the circle (adjust as needed)
					scale: 0.025, // Scale the icon size if necessary
				}),
				text: new Text({
					text: armySize.toString() + "K", // Display army size
					fill: new Fill({
						color: '#fff' // White text
					}),
					font: 'bold 16px sans-serif' // Font style for the text 
				})
			});
		}
	
		return style;
	}


	
	
	// Function to calculate small coordinate offsets
	getFeatureOffset(feature, size) {
		// Generate an offset angle and distance based on the number of overlapping features
		const angle = (Math.PI * 2 * Math.random()) / size; // Random angle based on number of features
		const distance = 500; // Small fixed distance to offset overlapping features
	
		const offsetX = Math.cos(angle) * distance;
		const offsetY = Math.sin(angle) * distance;
	
		return [offsetX, offsetY];
	}
	
	  

	highlightProvince(province, options = {}) {
		const feature = this.provincesLayer.getSource().getFeatures().find(f => f.get('id') == province.id);
		if (feature && feature.get('line') != 'true') {
			const highlightStyle = new Style({
				fill: options.isMoving ? new Fill({
					color: 'rgba(85, 189, 79)' // Different fill for selected state
				}) : feature.getStyle()?.getFill() || new Fill({ color: '#FAEBD7' }),
				stroke: new Stroke({
					color: options.isSelected ? '#464747' : '#185419', // Different stroke color for selected state
					width: 4,
					lineDash: options.isSelected ? undefined : [8, 8],
				}),
				text: options.isMoving ? new Text({
					text: "Move",
					font: 'bold 16px sans-serif',
				}) : null,
				zIndex: 5,
			});
	
			feature.setStyle(highlightStyle);
		}
	}

	resetProvinceStyle(province) {
		const feature = this.provincesLayer.getSource().getFeatures().find(f => f.get('id') == province.id);
		if (feature) {
			const highlightStyle = new Style({
				fill: province.color ? new Fill({ color: province.color }) : new Fill({ color: '#FAEBD7' }),
				stroke: new Stroke({
					color: '#404242', // Different stroke color for selected state
					width: 2,
					lineDash: undefined
				}),
				text: null,
			});
	
			feature.setStyle(highlightStyle);
		}
	}

	enterMoveMode(army) {
		this.movingArmy = army;  // Store the army that's being moved
		console.log("Select a location to move the army.");

		if (army.location_type == 'settlement') {
			const settlement = this.settlements[army.location];
			const province = this.provinces[settlement.province_id];
			this.highlightedProvinces.push(province.id);
			this.highlightProvince(province, { isMoving: true });
		}
		else {
			const province = this.provinces[army.location];
			const feature = this.provincesLayer.getSource().getFeatures().find(f => f.get('id') == province.id);
			// set zindex of feature to 99
			feature.getStyle().setZIndex(99);


			const borderProvinces = this.provinces[army.location].borderProvinces;
			for (const borderProvince of borderProvinces) {
				this.highlightedProvinces.push(borderProvince);
				this.highlightProvince(this.provinces[borderProvince], { isMoving: true });
			}
		}

		// Highlight the province the army is currently in
		//this.highlightProvince(province);

	}

	displayFeatureInfo(pixel, coordinate) {
		const feature = this.map.forEachFeatureAtPixel(pixel, function (feature) {
			return feature;
		});
	
		if (this.movingArmy) {
			if (feature && this.featureInLayer(feature, this.provincesLayer)) {
				const province = this.provinces[feature.get('id')];
				this.movingArmy.moveArmy(province.id, 'province');
				for (const province of this.highlightedProvinces) {
					this.resetProvinceStyle(this.provinces[province]);
				}
				this.movingArmy = null;
				this.highlightedProvinces = [];
			} else if (feature && this.featureInLayer(feature, this.settlementsLayer)) {
				const settlement = this.settlements[feature.get('id')];
				this.movingArmy.moveArmy(settlement.id, 'settlement');
				for (const province of this.highlightedProvinces) {
					this.resetProvinceStyle(this.provinces[province]);
				}
				this.highlightedProvinces = [];
				this.resetProvinceStyle(this.provinces[settlement.province_id]);
				this.movingArmy = null;
			} else {
				alert("Please select a valid province.");
			}
		} else {
			if (feature && this.featureInLayer(feature, this.provincesLayer) && feature.get('line') != 'true') {
				const province = this.provinces[feature.get('id')];
				if (feature !== this.highlight) {
					if (this.highlight) {
						this.resetProvinceStyle(this.provinces[this.highlight.get('id')]);
					}
					this.highlight = feature;
					this.highlightProvince(province, { isSelected: true });
				} else {
					// Deselect if the same province is clicked again
					this.resetProvinceStyle(province);
					this.highlight = null;
				}
				this.map.render();
				showPopUp("Province", province, coordinate);
				if (this.RightPanel == 'settlement') {
					closeRightPanel();
				}
				rightPanelSetInfo("province", province);
			} else if (feature && this.featureInLayer(feature, this.settlementsLayer)) {
				const settlement = this.settlements[feature.get('id')];
				showPopUp("Settlement", settlement, coordinate);
				if (this.RightPanel == 'province') {
					closeRightPanel();
				}
				rightPanelSetInfo("settlement", settlement);
			} else {
				this.overlay.setPosition(undefined);
				if (this.highlight) {
					this.resetProvinceStyle(this.provinces[this.highlight.get('id')]);
					this.highlight = null;
				}
				closePopUp();
				closeLeftPanel();
				closeRightPanel();
			}
		}
	}

	toggleArmyLayers(visible) {
		this.armyLayers.forEach(layer => layer.setVisible(visible));
	}
	

	//fetch and draw settlements as features
	async loadSettlements() {
		const response = await fetch('api/get_settlements');
		const settlementData = await response.json();
		this.settlements = {};

		settlementData.forEach(settlement => {
			const workingSettlement = new Settlement(settlement, this);
			this.settlements[settlement.id] = workingSettlement;
			workingSettlement.drawIcon();
		});


		 if (!window.currentUser) {
		   return;
		 }

		 // Check if userId matches any settlement user_id
		 const userHasSettlement = settlementData.some(settlement => settlement.user_id == window.currentUser.id);

		 if (!userHasSettlement) {
		   this.firstSettlementPlacementRequired = true;
		   this.promptFirstSettlementPlacement();
		 }
	}

	//fetch provinces and load the data into existing province features
	async loadProvinces() {
		const response = await fetch('api/get_provinces');
		const provinceData = await response.json();
		this.provinces = {};

		provinceData.forEach(province => {
			const workingProvince = new Province(province, this);
			this.provinces[province.id] = workingProvince;
			workingProvince.loadProvinceData();
		});
		
	}

	async loadArmies() {
		const response = await fetch('api/get_armies');
		const armyData = await response.json();
		this.armies = {};

		// Group armies by their nation_id
		const armiesByNation = armyData.reduce((acc, army) => {
			if (army.location_type === 'province') {
				// Initialize array for each nation if not already initialized
				if (!acc[army.nation_id]) {
					acc[army.nation_id] = [];
				}
				acc[army.nation_id].push(army);
			}
			return acc;
		}, {});

		// Create a cluster and layer for each nation
		Object.keys(armiesByNation).forEach(nationId => {
			// Create a new source and cluster source for each nation
			const armySource = new VectorSource();

			const nationClusterSource = new Cluster({
				distance: 50, // Distance for clustering
				source: armySource
			});

			// Create army features for each army belonging to this nation
			const armyFeatures = armiesByNation[nationId].map(army => {
				const province = this.provinces[army.location]; // Get the province by location
				
				if (!province) {
					console.warn(`Province not found for army in province ${army.location}`);
					return null;
				}

				const provinceFeature = this.provincesLayer.getSource().getFeatures().find(f => f.get('id') == province.id);
				if (!provinceFeature) {
					console.warn(`Feature not found for province ${province.id}`);
					return null;
				}

				const provinceCenter = provinceFeature.getGeometry().getInteriorPoint().getCoordinates();

				// Create a feature for this army
				const armyFeature = new Feature({
					geometry: new Point(provinceCenter),
					size: army.size,
					nation_id: army.nation_id, // Add nation_id to the feature for identification
					nation_color: army.nation_color,
				});

				return armyFeature;
			}).filter(feature => feature !== null);

			// Add features to the respective source
			armySource.addFeatures(armyFeatures);

			// Create a new layer for this nation's cluster
			const armyLayer = new VectorLayer({
				source: nationClusterSource,
				style: this.getArmyClusterStyle.bind(this),
				zIndex: 100
			});
			this.armyLayers.push(armyLayer);

			// Add the layer to the map
			//console.log(this.map.getLayers().getArray());
			this.map.addLayer(armyLayer);
		});

		// Save army data for later use (unchanged)
		armyData.forEach(army => {
			const workingArmy = new Army(army, this);
			this.armies[army.id] = workingArmy;
		});
	}
	
	
	
	  
}