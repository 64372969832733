import './ui/leftpanel.js';
import './ui/rightpanel.js';
import { supabase } from './supabaseClient.js';

export function panelShowInfo(panel, type) {
  if (type == 'province') {
    panel.querySelector('#right-panel-province').style.display = 'block';
    panel.querySelector('#right-panel-settlement').style.display = 'none';
  } else if (type == 'settlement') {
    panel.querySelector('#right-panel-province').style.display = 'none';
    panel.querySelector('#right-panel-settlement').style.display = 'block';
  }
}

async function logout() {
	await supabase.auth.signOut();
	window.currentUser = null;
	window.location.href = '/login.html';
  }
  

// Attach the logout function to the window object
window.logout = logout;

const settlementCheckbox = document.getElementById('settlement-checkbox');
const armyCheckbox = document.getElementById('army-checkbox');

// Toggle settlement layer visibility
settlementCheckbox.addEventListener('change', (event) => {
  window.map.settlementsLayer.setVisible(event.target.checked);
});

// Toggle army layer visibility
armyCheckbox.addEventListener('change', (event) => {
  window.map.toggleArmyLayers(event.target.checked); // Toggle all army layers
});

// Clock code
function getNextThirdHourTime() {
  const now = new Date();
  let currentHour = now.getHours();

  // Calculate the next 3rd hour
  let nextThirdHour = currentHour - 1 + (3 - (currentHour % 3));
  if (currentHour % 3 === 0 && now.getMinutes() === 0 && now.getSeconds() === 0) {
    nextThirdHour = currentHour;
  }

  // Handle rollover to the next day
  if (nextThirdHour >= 24) {
    nextThirdHour = nextThirdHour % 24;
  }

  const nextTick = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + (nextThirdHour <= currentHour ? 1 : 0),
    nextThirdHour,
    0,
    0,
    0
  );

  return nextTick;
}

function updateClock() {
  const now = new Date();
  const nextTick = getNextThirdHourTime();
  const timeRemaining = nextTick - now; // in milliseconds

  const totalSeconds = Math.floor(timeRemaining / 1000);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format the time as HH:MM:SS
  const timeString = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

  // Update the clock on the page
  const clockElement = document.getElementById('clock-time');
  if (clockElement) {
    clockElement.textContent = `${timeString}`;
  }
}

function padZero(num) {
  return num < 10 ? '0' + num : num;
}

// Update the clock every second
setInterval(updateClock, 1000);

// Initial call
updateClock();




async function getUser() {
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser();

  if (user) {
    document.getElementById('user-display').textContent = user.user_metadata.display_name;
    document.querySelector('.login-button').style.display = 'none';
    document.querySelector('.signup-button').style.display = 'none';
    window.currentUser = user;
    return user;
  } else {
    document.querySelector('.logout-button').style.display = 'none';
    window.currentUser = null;
  }
}

getUser();
