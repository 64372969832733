// Left panel for nations
const nationButton = document.getElementById('nation-button');
const leftSlidingPanel = document.getElementById('left-sliding-panel');

nationButton.addEventListener('click', function () {
	leftSlidingPanel.classList.toggle('open');
});

export function closeLeftPanel() {
	leftSlidingPanel.classList.remove('open');
}