import { set } from "ol/transform.js";
import { panelShowInfo } from "../index.js";
import { closePopUp } from "./popup.js";
import { supabase } from '../supabaseClient.js';

// Right panel for provinces/settlement
const provinceButton = document.getElementById('province-button');
const settlementButton = document.getElementById('settlement-button');
const rightSlidingPanel = document.getElementById('right-sliding-panel');

const rightPanelMergeBtnSettlement = document.getElementById('rp-army-merge-btn');
const rightPanelMergeBtnProvince = document.getElementById('rp-province-army-merge-btn');

const rightPanelSplitContSettlement = document.getElementById('settlement-split-off-container');
const rightPanelSplitContProvince = document.getElementById('province-split-off-container');
const splitButtonSettlement = document.getElementById('rp-army-split-btn');
const splitButtonProvince = document.getElementById('rp-province-army-split-btn');

const rightPanelRecruitContSettlement = document.getElementById('settlement-recruit-container');
const rightPanelRecruitBtnSettlement = document.getElementById('rp-army-recruit-btn');

let currentSettlementId = null;

let selectedArmies = new Set();

provinceButton.addEventListener('click', function () {
	rightSlidingPanel.classList.toggle('open');
	panelShowInfo(rightSlidingPanel, 'province');
	closePopUp();

});

settlementButton.addEventListener('click', function () {
	rightSlidingPanel.classList.toggle('open');
	panelShowInfo(rightSlidingPanel, 'settlement');
	closePopUp();
});


export function rightPanelSetInfo(type, obj) {
	if (type == 'settlement') {

		window.map.RightPanel = 'settlement';
		currentSettlementId = obj.id;

		document.getElementById('rp-settlement-name').innerHTML = obj.name;
		document.getElementById('rp-settlement-owner').innerHTML = obj.user_display_name;
		document.getElementById('rp-settlement-manpower').innerHTML = obj.manpower;


		rightPanelMergeBtnSettlement.style.display = 'none';
		rightPanelSplitContSettlement.style.display = 'none';
		rightPanelRecruitContSettlement.style.display = 'none';
		loadArmies(obj);
	}

	if (type == 'province') {
		window.map.RightPanel = 'province';

		document.getElementById('rp-province-owner').innerHTML = obj.nation_name;

		rightPanelSplitContProvince.style.display = 'none';
		rightPanelMergeBtnProvince.style.display = 'none';
		loadArmies(obj);
	}
}

export function closeRightPanel() {
	rightSlidingPanel.classList.remove('open');
}

export async function mergeArmies(armies) {
	const armyIds = Array.from(armies).map(army => army.id);
	let army = armies.values().next().value;


	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;

	if (armyIds.length > 1) {
		fetch('/api/merge_armies', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify({ army_ids: armyIds })
		})
		.then(response => response.json())
			.then(async data => {
				if (data.message == 'success') {
					console.log("Army merged successfully");

					await window.map.loadArmies();

					//console.log(army);

					if (army.location_type == 'settlement') {
						loadArmies(window.map.settlements[army.location]);
					}
					else {
						loadArmies(window.map.provinces[army.location]);
					}

				}
			});
	}
}

export async function splitArmy(army, splitSize) {
	const { data: { session } } = await supabase.auth.getSession();
	const accessToken = session.access_token;

	console.log("entering splitArmy function");
  
	fetch('/api/split_army', {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${accessToken}`
	  },
	  body: JSON.stringify({ army_id: army.id, split_size: splitSize })
	})
	.then(response => response.json())
	.then(async data => {
	  if (data.message == 'success') {
		console.log("Army split successfully");
  
		await window.map.loadArmies();
  
		if (army.location_type == 'settlement') {
		  loadArmies(window.map.settlements[army.location]);
		} else {
		  loadArmies(window.map.provinces[army.location]);
		}
	  } else {
		console.error("Error splitting army:", data.error);
	  }
	});
  }

  async function recruitArmy(settlementId, recruitNumber) {
	console.log("Recruit army function called");
    const { data: { session } } = await supabase.auth.getSession();
    const accessToken = session.access_token;
    fetch('/api/recruit_army', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({ settlement_id: settlementId, recruit_size: recruitNumber })
    })
    .then(response => response.json())
    .then(async data => {
        if (data.message == 'success') {
            console.log("Army recruited successfully");
            document.getElementById('rp-settlement-manpower').innerHTML = data.updated_manpower;
            await window.map.loadArmies();
            loadArmies(window.map.settlements[settlementId]);
        } else {
            console.error("Error recruiting army:", data.error);
            alert("Error recruiting army: " + data.error);
        }
    })
    .catch(error => {
        console.error("Error:", error);
        alert("Error recruiting army: " + error.message);
    });
}  

async function recruitButtonHandler() {
    var recruitNumber = parseInt(document.getElementById('rp-settlement-recruit-number').value);
    await recruitArmy(currentSettlementId, recruitNumber);
}

async function splitButtonHandler() {
	var splitSize = parseInt(document.getElementById('rp-settlement-split-off-number').value);
	if (selectedArmies.size !== 1) {
		alert("Please select exactly one army to split.");
		return;
	}
	if (isNaN(splitSize) || splitSize <= 0) {
		alert("Please enter a valid split size.");
		return;
	}
	const army = selectedArmies.values().next().value;
	if (splitSize >= army.size) {
		alert("Split size must be less than army size.");
		return;
	}
	await splitArmy(army, splitSize);
}

async function splitButtonProvinceHandler() {
	console.log("Split button clicked");
	const splitSize = parseInt(document.getElementById('rp-province-split-off-number').value);
	if (selectedArmies.size !== 1) {
		alert("Please select exactly one army to split.");
		return;
	}
	if (isNaN(splitSize) || splitSize <= 0) {
		alert("Please enter a valid split size.");
		return;
	}
	const army = selectedArmies.values().next().value;
	if (splitSize >= army.size) {
		alert("Split size must be less than army size.");
		return;
	}
	await splitArmy(army, splitSize);
}

export async function loadArmies(obj) {
    const armies = window.map.armies;
    const armyList = document.getElementById('army-list');
	const provinceArmyList = document.getElementById('rp-province-army-list');

    armyList.innerHTML = '';
	provinceArmyList.innerHTML = '';

	if (window.currentUser.id == obj.user_id) {
		rightPanelRecruitContSettlement.style.display = 'flex';
	}

    for (let army in armies) {
        army = armies[army];
        if (army.location == obj.id && army.location_type == obj.location_type) {
            const armyItem = document.createElement('li');
            armyItem.classList.add('army-item');

            const armyColorFlag = document.createElement('div');

            if (army.loyal) {
                armyColorFlag.classList.add('army-color-flag');
                armyColorFlag.style.backgroundColor = army.nation_color;
            }
            else if (army.loyal == null) {
                armyColorFlag.classList.add('army-color-flag');
                armyColorFlag.style.backgroundColor = "yellow"
            }
            else {
                armyColorFlag.classList.add('army-color-flag-rebel');
                armyColorFlag.style.backgroundColor = "red"
            }

            const armyInfoContainer = document.createElement('div');
            armyInfoContainer.classList.add('army-info-container');

            const armyText = document.createElement('div');
            armyText.innerHTML = `${army.size}K Infantry`;

            const armyDestination = document.createElement('div');
            armyDestination.classList.add('army-destination');

            armyInfoContainer.appendChild(armyText);

            if (army.destination && army.destination_type) {
                armyDestination.innerHTML = `${army.settlement_name} - Moving to ${army.destination_type} ${army.destination}`;
            }
            else {
                armyDestination.innerHTML = `${army.settlement_name} - Idle`;
            }


            armyInfoContainer.appendChild(armyDestination);

            armyItem.appendChild(armyColorFlag);
            armyItem.appendChild(armyInfoContainer);
			//armyItem.appendChild(armyOwner);

            if (window.currentUser.id == army.user_id) {
                const armyButton = document.createElement('button');
                armyButton.classList.add('side-panel-button');
                armyButton.innerHTML = 'Move';
                armyButton.addEventListener('click', function () {
                    console.log("Move clicked");
                    window.map.enterMoveMode(army);
					closeRightPanel();
					closePopUp();
                });
                armyItem.appendChild(armyButton);
				rightPanelMergeBtnProvince.style.display = 'block';
				rightPanelMergeBtnSettlement.style.display = 'block';
				rightPanelSplitContProvince.style.display = 'flex';
				rightPanelSplitContSettlement.style.display = 'flex';
            }

			// Add click event listener for selecting and deselecting army items
            armyItem.addEventListener('click', function () {
                if (selectedArmies.has(army)) {
                    selectedArmies.delete(army);
                    armyItem.classList.remove('selected');
                } else {
                    selectedArmies.add(army);
                    armyItem.classList.add('selected');
                }
            });

			if (army.location_type == 'settlement') {
            	armyList.appendChild(armyItem);
			}
			else if (army.location_type == 'province') {
				provinceArmyList.appendChild(armyItem);
			}
        }
    }

	rightPanelMergeBtnSettlement.addEventListener('click', async function () {
		await mergeArmies(selectedArmies);
	});

	rightPanelMergeBtnProvince.addEventListener('click', async function () {
		await mergeArmies(selectedArmies);
	});

	rightPanelRecruitBtnSettlement.removeEventListener('click', recruitButtonHandler);
	rightPanelRecruitBtnSettlement.addEventListener('click', recruitButtonHandler);

	splitButtonSettlement.removeEventListener('click', splitButtonHandler);
	splitButtonSettlement.addEventListener('click', splitButtonHandler);

	splitButtonProvince.removeEventListener('click', splitButtonProvinceHandler);
	splitButtonProvince.addEventListener('click', splitButtonProvinceHandler);
}
